import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngDaoDevelopment = loadable(() => import("../components/DaoDevelopment/english.js"));
const ArabicDaoDevelopment = loadable(() => import("../components/DaoDevelopment/arabic.js"));
const ChineseDaoDevelopment = loadable(() => import("../components/DaoDevelopment/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-16.webp",
            "name": "DAO Development Company",
            "description": "Softtik is the leading DAO development company with cost-effective, reliable, and secure solutions. Grow your business with our top-notch services.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "125"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "Can DAO make you rich?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Starting a DAO could enable a firm to reach a more comprehensive number of investors and raise a significant amount of money. It's a lucrative alternative because you can reach out to investors worldwide with no legal stumbling blocks.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How can I invest in DAO?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>There are several ways to invest in the Decentralized Autonomous Organization (DAO). You can buy DAO crypto tokens on Binance, Coinbase, etc. But if you want to develop a DAO to get funding for your project, <b>Softtik technologies</b> is your go-to partner.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Is Bitcoin a DAO?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Bitcoin can be seen as a Decentralized Autonomous Organization (DAO) because users agree to trade flagship currency, and everything is recorded. The majority of DAOs that exist today run on the Ethereum network.</p>"
                }
            }
            ]
        }
    ]
}

export class DaoDevelopment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English"
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;
        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/dao-development-services/"
                        title="DAO Development Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-16.webp"
                        description="Softtik is the leading DAO development company with cost-effective, reliable, and secure solutions. Grow your business with our top-notch services. "
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicDaoDevelopment />
                                    : lang == "China"
                                        ? <ChineseDaoDevelopment />
                                        : <EngDaoDevelopment />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default DaoDevelopment;